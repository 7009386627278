import './App.css';

function App() {
  return (
    <div className="flex flex-col max-w-screen-lg min-h-screen m-auto items-center justify-center">
      <code>mandy.lol</code>
    </div>
  );
}

export default App;
